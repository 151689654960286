.paginationWrapper {

  & ul {
    margin: 50px 0;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &.-between {
    & ul {
     justify-content: space-between;
    }
  }

  & li {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child{
      margin-right: 24px;
    }

    &:last-child{
      margin-left: 24px;
    }

    &:first-child, &:last-child {
      border: 1px solid theme('colors.divider');
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }
}
